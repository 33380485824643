import Footer from "components/Footer";
import Menu from "components/Menu/index";
import TopBar from "components/TopBar";
import service_1 from "assets/img/icon/services-1.png";
import service_11 from "assets/img/icon/services-11.png";
import service_2 from "assets/img/icon/services-2.png";
import service_22 from "assets/img/icon/services-22.png";
import service_3 from "assets/img/icon/services-3.png";
import service_33 from "assets/img/icon/services-33.png";
import assistant_ai from "assets/img/EC_Virtual_Assistant_AI_750.jpg";
import footerBg from "assets/img/shape/footer.png";
import banner from "assets/img/home-banner.jpg";
import bannerLogo from "assets/img/home-banner-logo.svg";
import personWatching from "assets/img/person-watching-video-wall-with-multimedia-images-different-television-screens-generative-ai.jpg";
import serviceBanner from "assets/img/service-detail-banner.jpg";
import serviceBg from "assets/img/bg-small-part-1.svg";

import { Box, Button, Grid, Typography } from "@mui/material";
import ServiceTag from "components/ServiceTag";
import ContactBox from "components/Contact";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ServiceOffter from "components/ServiceOffer";
import { ImageHandle } from "components/ImageHandler";

export default function HomePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      {/* <!-- Preloader Start --> */}
      {/* <div className="theme-loader">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div> */}
      {/* Main content */}
      {/* <TopBar /> */}
      {/* <!-- Top Bar End -->
    <!-- Header Area Start --> */}
      {/* <Menu currentTab={0} /> */}
      {/* <!-- Header Area End -->
    <!-- Banner Area Start --> */}
      <Box
        sx={{
          background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${banner})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: 600,
          marginBottom: "150px",
          "@media (max-width: 500px)": {
            height: 300,
            marginBottom: "50px",
          },
        }}
      >
        <Grid container height="inherit">
          <Grid
            item
            xs={12}
            md={6}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography className="text-banner">
              {t("banner_title_1")}
            </Typography>
            <Typography className="text-banner">
              {t("banner_title_2")}
            </Typography>
            <Button
              variant="contained"
              className="btn-red"
              onClick={() => navigate("/services/detail/1")}
            >
              {t("detail_btn")}
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ position: "relative" }}>
            <img
              src={bannerLogo}
              alt="logo"
              className="extendImgLogo"
              style={{
                position: "absolute",
                bottom: "-230px",
                left: 0,
                right: 0,
                margin: "auto",
                width: 550,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      {/* <!-- Banner Area End -->
    <!-- Services Area Start --> */}
      <ServiceOffter />
      {/* Box */}
      <Box
        sx={{
          margin: "50px 0 100px 0",
          "@media (max-width: 500px)": {
            margin: "30px 0 0px 0",
          },
        }}
      >
        <ImageHandle src={personWatching} className="cropped-image" />
      </Box>
      {/* Preview Area */}
      <Grid>
        <Box
          sx={{
            padding: "50px 200px",
            margin: "100px 0px",
            "@media (max-width: 500px)": {
              padding: 0,
              margin: "30px 10px",
            },
            backgroundImage: `url(${serviceBg})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: "400px",
              backgroundImage: `url(${serviceBanner})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundColor: "#D2232A",
              // mixBlendMode: "multiply"
            }}
          >
            <Grid container height="inherit">
              <Grid
                item
                xs={12}
                md={6}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography className="text-banner">
                  {t("banner_title_1")}
                </Typography>
                <Typography className="text-banner">
                  {t("banner_title_2")}
                </Typography>
                <Button
                  variant="contained"
                  className="btn-red"
                  onClick={() => navigate("/services/detail/1")}
                >
                  {t("detail_btn")}
                </Button>
              </Grid>
              <Grid item xs={6} sx={{ position: "relative" }}></Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      {/* Contact */}
      <Grid>
        <ContactBox />
      </Grid>

      <Footer />
    </>
  );
}
