import { Box, Grid, Typography } from "@mui/material";
import ServiceTag from "../ServiceTag";
import { useTranslation } from "react-i18next";

export default function ServiceOffter() {
  const { t } = useTranslation();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      mb="100px"
      sx={{
        marginBottom: "100px",
        "@media (max-width: 500px)": {
          marginBottom: "50px",
        },
      }}
    >
      <Typography className="tag-title">{t("title_offer")}</Typography>
      <Typography
        sx={{
          textTransform: "capitalize",
          fontSize: "40px",
          margin: "30px",
          textAlign: "center",
          maxWidth: "700px",
          "@media (max-width: 500px)": {
            fontSize: "24px",
            margin: "20px",
          },
        }}
      >
        {t("explore_text")}
      </Typography>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "right",
            "@media (max-width: 500px)": {
              justifyContent: "center",
            },
          }}
        >
          <ServiceTag
            title={t("ai_service_title")}
            description={t("ai_service_description")}
            link="/services/detail/1"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "left",
            "@media (max-width: 500px)": {
              justifyContent: "center",
            },
          }}
        >
          <ServiceTag
            title={t("chatbot_service_title")}
            description={t("chatbot_service_description")}
            link="/services/detail/2"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          justifyContent={"right"}
          display={"flex"}
          sx={{
            display: "flex",
            justifyContent: "right",
            "@media (max-width: 500px)": {
              justifyContent: "center",
            },
          }}
        >
          <ServiceTag
            title={t("data_analytics_service_title")}
            description={t("data_analytics_service_description")}
            link="/services/detail/3"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "left",
            "@media (max-width: 500px)": {
              justifyContent: "center",
            },
          }}
        >
          <ServiceTag
            title={t("smart_hr_service_title")}
            description={t("smart_hr_service_description")}
            link="/services/detail/4"
          />
        </Grid>
      </Grid>
      {/* <Box
        display={"flex"}
        sx={{
          "& > div": {
            margin: "20px",
          },
        }}
      >
        <ServiceTag
          title={t("ai_service_title")}
          description={t("ai_service_description")}
          link="/services/detail/1"
        />
        <ServiceTag
          title={t("chatbot_service_title")}
          description={t("chatbot_service_description")}
          link="/services/detail/2"
        />
      </Box>
      <Box
        display={"flex"}
        sx={{
          "& > div": {
            margin: "20px",
          },
        }}
      >
        <ServiceTag
          title={t("data_analytics_service_title")}
          description={t("data_analytics_service_description")}
          link="/services/detail/3"
        />
        <ServiceTag
          title={t("smart_hr_service_title")}
          description={t("smart_hr_service_description")}
          link="/services/detail/4"
        />
      </Box> */}
    </Box>
  );
}
