import {
  Box,
  Button,
  Divider,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import contactBg from "../../assets/img/contact-bg.svg";
import positionIcon from "../../assets/img/position-icon.svg";
import phoneIcon from "../../assets/img/icon-website-03.svg";
import emailIcon from "../../assets/img/icon-website-04.svg";
import pointerIcon from "../../assets/img/icon-website-05.svg";
import { useTranslation } from "react-i18next";
import { ImageHandle } from "components/ImageHandler";
export default function ContactBox() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        position: "relative",
        padding: "50px 200px",
        "@media (max-width: 500px)": {
          padding: 0,
          margin: "30px 10px",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "200px",
          height: "700px",
          margin: "auto",
          right: 50,
          top: 0,
          bottom: 0,
          backgroundColor: "#e4e4e4",
          "@media (max-width: 500px)": {
            width: "100%",
            height: "300px",
            right: "unset",
            top: "50px",
            bottom: "unset",
          },
        }}
      />
      <Box
        sx={{
          position: "absolute",
          width: "350px",
          height: "400px",
          margin: "auto",
          right: 100,
          top: 0,
          bottom: 0,
          backgroundImage: `url(${contactBg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#d71c1c",
          padding: "10px",
          zIndex: 3,
          "@media (max-width: 500px)": {
            position: "relative",
            right: "unset",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: 26,
            color: "white",
            marginBottom: "15px",
          }}
        >
          {t("information")}
        </Typography>
        <Box display="flex" color="white">
          <ImageHandle src={emailIcon} alt="icon" width="40px" />
          <Box display="flex" flex={1} alignSelf="stretch" alignItems="center">
            <Typography>info@aivision.vn </Typography>
          </Box>
        </Box>
        <Box display="flex" color="white" my={1}>
          <ImageHandle src={pointerIcon} alt="icon" width="40px" />
          <Box display="flex" flex={1} alignSelf="stretch" alignItems="center">
            <Typography>aivision.vn </Typography>
          </Box>
        </Box>

        <Box display="flex" color="white" alignItems="start" my={1}>
          <ImageHandle src={positionIcon} alt="icon" width="40px" />
          <Box display="flex" flexDirection="column">
            <Box display="flex">
              <Box
                width="5px"
                height="5px"
                bgcolor="white"
                borderRadius="25px"
                m={1}
              />
              <Typography fontSize="13px">
                <span style={{ fontWeight: "bold" }}>Vietnam:</span> 202 Le Lai,
                District 1, HCMC.
              </Typography>
            </Box>

            <Box display="flex">
              <Box
                width="10px"
                height="5px"
                bgcolor="white"
                borderRadius="25px"
                m={1}
              />
              <Typography fontSize="13px">
                <span style={{ fontWeight: "bold" }}>Vietnam Branch:</span>{" "}
                Floor 14, HM Town, 412 Nguyen Thi Minh Khai, District 3, HCMC.
              </Typography>
            </Box>

            <Box display="flex">
              <Box
                width="5px"
                height="5px"
                bgcolor="white"
                borderRadius="25px"
                m={1}
              />
              <Typography fontSize="13px">
                <span style={{ fontWeight: "bold" }}>Australia:</span> 13
                Birmingham st, Springvale, Victoria.
              </Typography>
            </Box>

            <Box display="flex">
              <Box
                width="5px"
                height="5px"
                bgcolor="white"
                borderRadius="25px"
                m={1}
              />
              <Typography fontSize="13px">
                <span style={{ fontWeight: "bold" }}>USA:</span> 3342 Trent
                Stone Lane, Katy, Texas.
              </Typography>
            </Box>

            <Box display="flex">
              <Box
                width="5px"
                height="5px"
                bgcolor="white"
                borderRadius="25px"
                m={1}
              />
              <Typography fontSize="13px">
                <span style={{ fontWeight: "bold" }}>Singapore:</span> 350137,
                Potong Pasir Ave 3.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" color="white" alignItems="start" my={1}>
          <ImageHandle src={phoneIcon} alt="icon" width="40px" />
          <Box display="flex" flexDirection="column">
            <Box display="flex">
              <Box
                width="5px"
                height="5px"
                bgcolor="white"
                borderRadius="25px"
                m={1}
              />
              <Typography fontSize="13px">
                <span style={{ fontWeight: "bold" }}>Vietnam:</span> +(84) 981 419 967
              </Typography>
            </Box>

            <Box display="flex">
              <Box
                width="5px"
                height="5px"
                bgcolor="white"
                borderRadius="25px"
                m={1}
              />
              <Typography fontSize="13px">
                <span style={{ fontWeight: "bold" }}>Singapore:</span> +(65) 805 389 57
              </Typography>
            </Box>

            <Box display="flex">
              <Box
                width="5px"
                height="5px"
                bgcolor="white"
                borderRadius="25px"
                m={1}
              />
              <Typography fontSize="13px">
                <span style={{ fontWeight: "bold" }}>USA:</span> +(1) 713 540 3935
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/************ Contact input fields ************/}
      <Box
        sx={{
          boxShadow: "2px 2px 5px #888888",
          padding: "50px",
          position: "relative",
          zIndex: 2,
          backgroundColor: "white",
        }}
      >
        <Typography
          fontSize={"40px"}
          sx={{
            "@media (max-width: 500px)": {
              fontSize: "24px",
            },
          }}
        >
          {t("contact_box_title")}
        </Typography>
        <Typography
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            "@media (max-width: 500px)": {
              fontSize: "14px",
            },
          }}
        >
          {t("contact_box_description")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "50%",

            "& input": {
              border: "none",
              background: "transparent",
            },
            "@media (max-width: 500px)": {
              maxWidth: "100%",
              "& input": {
                height: "40px",
              },
            },
          }}
        >
          <TextField
            label={t("contact_box_name")}
            variant="standard"
            sx={{ marginBottom: "10px" }}
          />
          <TextField
            label={t("contact_box_email")}
            variant="standard"
            sx={{ marginBottom: "10px" }}
          />
          <TextField
            label={t("contact_box_message")}
            variant="standard"
            onChange={(e) => console.log(e.target.value)}
            sx={{ marginBottom: "10px" }}
          />
          <Button
            variant="contained"
            className="btn-red"
            sx={{ marginTop: "30px" }}
            onClick={() => (window.location = "mailto:info@aivision.vn")}
          >
            {t("send")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
