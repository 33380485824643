import { ArrowUpward, KeyboardArrowUp, Rocket } from "@mui/icons-material";
import { Box, Fab, Tooltip, keyframes } from "@mui/material";

const descendAnimation = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ascendAnimation = keyframes`
  from {
    transform: translateY(0%);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;

export default function BackToTopButton({ show }) {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <Box
      p={2}
      sx={{
        // display: show ? "" : "none",
        animation: `${
          show ? descendAnimation : ascendAnimation
        } .2s ease-out forwards`,
      }}
    >
      <Tooltip title="Scroll to top" placement="left">
        <Fab color="secondary" onClick={handleScrollToTop}>
          <Rocket />
        </Fab>
      </Tooltip>
    </Box>
  );
}
