import { useState, useRef } from "react";
import { ChatBubble } from "@mui/icons-material";
import * as React from "react";

import { Fab, Box, Tooltip } from "@mui/material";

import ChatBox from "./ChatBox";
import MaintenanceModal from "./MaintenanceModal";

const CHATBOT_MAINTENANCE = false;

export default function CustomChatWidget() {
  // Chatbot FAB
  const btnRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  // Selector

  const handleOpenChat = (e) => {
    // e.stopPropagation();
    setAnchorEl(btnRef.current);
  };

  const handleCloseChat = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box ref={btnRef} p={2}>
      <Tooltip title="Chatbot" placement="left">
        <Fab
          onClick={handleOpenChat}
          color="primary"
          // sx={{ padding: 2, position: "fixed", bottom: 50, right: 30 }}
        >
          <ChatBubble />
        </Fab>
      </Tooltip>

      {CHATBOT_MAINTENANCE ? (
        <MaintenanceModal
          anchorEl={anchorEl}
          handleOpenChat={handleOpenChat}
          handleCloseChat={handleCloseChat}
        />
      ) : (
        <ChatBox
          anchorEl={anchorEl}
          handleOpenChat={handleOpenChat}
          handleCloseChat={handleCloseChat}
        />
      )}
    </Box>
  );
}
