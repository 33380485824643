export const language = [
  {
    label: "Vietnamese",
    value: "vi",
  },
  {
    label: "English",
    value: "en",
  },
];
