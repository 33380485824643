import { axiosClient } from "utils/axiosClient";

export const adminApi = {
  getAllBlogs(page, limit) {
    return axiosClient.get("posts/all", {
      params: {
        page,
        limit,
      },
    });
  },
  getBlogBySlug(slug) {
    return axiosClient.get(`posts/${slug}`);
  },
  createBlog(data) {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("image", data.image[0].file);
    return axiosClient.post("posts", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateBlog(slug, data) {
    return axiosClient.put(`admin/blogs/${slug}`, data);
  },
  deleteBlog(post_id) {
    return axiosClient.delete(`posts/${post_id}`);
  },
  deleteBlogImage(image_name) {
    return axiosClient.delete(`posts/images/${image_name}`);
  },
};
