import Footer from "../../components/Footer";
import Menu from "../../components/Menu/index";
import womanTouch from "../../assets/img/social-web-network-mixed-media.jpg";

import bgPatternCircle from "../../assets/img/bg-pattern-circle.png";
import banner from "../../assets/img/about-banner.jpg";

import { Box, Button, Grid, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import ServiceBox from "../../components/ServiceBox";
import ServiceTag from "../../components/ServiceTag";
import TeammateBox from "../../components/TeammateBox";

import giangImg from "../../assets/img/team/Giang.png";
import quangImg from "../../assets/img/team/Quang.png";
import theImg from "../../assets/img/team/The.png";
import huyImg from "../../assets/img/team/Huy.png";
import tAnhImg from "../../assets/img/team/TanAnh.png";
import hieuImg from "../../assets/img/team/Hieu.png";
import thanhImg from "../../assets/img/team/Thanh.png";
import ngaImg from "../../assets/img/team/nga.png";
import locImg from "../../assets/img/team/loc.png";
import hiepImg from "../../assets/img/team/hiep.png";
import hiephuynhImg from "../../assets/img/team/hiephuynh.png";
import Slider from "react-slick";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ServiceOffter from "../../components/ServiceOffer";

import hqSoft from "../../assets/img/partners/hqsoft.png";
import dustin from "../../assets/img/partners/dustion.png";
import quangCom from "../../assets/img/partners/quang-company.png";
// import tefal from "../../assets/img/partners/tefal.png";
import uzip from "../../assets/img/partners/uzip.png";
import cungTruong from "../../assets/img/partners/cung-truong.png";
import ivn from "../../assets/img/partners/ivn-talent.png";
import lienhiep from "../../assets/img/partners/lienhiep.png";
import lightning from "../../assets/img/partners/lightning.png";
import sponsor from "../../assets/img/partners/sponsor.png";
import stayCare from "../../assets/img/partners/stay-care.png";

const teamList = [
  {
    name: "Msc. Hiep Huynh",
    role: "CEO - Chief Executive Officer",
    image: hiephuynhImg,
    link: "",
  },
  {
    name: "Msc. Giang Vo",
    role: "CTO - Chief Technology Officer",
    image: giangImg,
    link: "",
  },
  // {
  //   name: "PHD. Quang Le",
    //   role: "Senior. AI Scientist, Product Lead",
    //   image: quangImg,
    //   link: "",
  // },
  // {
  //   name: "PHD. The Le",
    //   role: "Senior. AI Scientist",
    //   image: theImg,
    //   link: "",
  // },
  // {
  //   name: "PHD. Huy Bui",
    //   role: "Senior. AI Scientist",
    //   image: huyImg,
    //   link: "",
  // },
  {
    name: "Bsc. Anh Nguyen",
    role: "AI Scientist, Research Lead",
    image: tAnhImg,
    link: "",
  },
  // {
  //   name: "Bsc. Hieu Hoang",
    //   role: "Senior. SE, Project Manager",
    //   image: hieuImg,
    //   link: "",
  // },
  // {
  //   name: "Giang Phan",
  //   role: "Sale Manager",
  //   image: giangPhanImg,
  //   link: "",
  // },
  // {
  //   name: "Bsc. Thanh Phuong",
  //   role: "Data Scientist",
  //   image: thanhImg,
  //   link: "",
  // },
  // {
  //   name: "Bsc. Nga Pham",
  //   role: "Data Scientist",
  //   image: ngaImg,
  //   link: "",
  // },
  // {
  //   name: "Bsc. Loc Huynh",
  //   role: "AI Scientist",
  //   image: locImg,
  //   link: "",
  // },
  // {
  //   name: "Bsc. Hiep Tran",
  //   role: "AI Scientist",
  //   image: hiepImg,
  //   link: "",
  // },
];

const clientList = [
  {
    img: dustin,
    width: 180,
    height: 80,
  },
  {
    img: uzip,
    width: 180,
    height: 80,
  },
  {
    img: hqSoft,
    width: 220,
  },
  // {
  //   img: quangCom,
  //   width: 150,
  //   height: 70,
  // },
  {
    img: stayCare,
  },
  {
    img: lienhiep,
  },
  {
    img: lightning,
  },
  {
    img: sponsor,
  },
  {
    img: cungTruong,
    width: 150,
  },
  {
    img: ivn,
    width: 150,
    height: 60,
  },
];

// var settings = {
//   dots: true,
//   infinite: true,
//   speed: 200,
//   slidesToShow: 4,
//   slidesToScroll: 1,
//   autoplay: true,
// };
export default function TeamPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [setting, setSetting] = useState({
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
  });

  useEffect(() => {
    if (window.innerWidth <= 500) {
      setSetting({
        dots: true,
        infinite: true,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
      });
    }
  }, []);
  return (
    <>
      {/* <Menu currentTab={1} /> */}
      {/* <!-- Header Area End -->
    <!-- Banner Area Start --> */}
      <Box
        sx={{
          background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${banner})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: 600,
          marginBottom: "50px",
          "@media (max-width: 500px)": {
            height: 300,
          },
        }}
      >
        <Grid container height="inherit">
          <Grid
            item
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"end"}
            alignItems={"center"}
            mb="60px"
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              padding={"10px 20px"}
              sx={{
                background: "#d61f23",
                color: "white",
                fontSize: "30px",
                textTransform: "uppercase",
                marginBottom: "15px",
              }}
            >
              <Typography
                onClick={() => navigate("/")}
                sx={{ cursor: "pointer" }}
              >
                {t("home_page_title")}
              </Typography>
              <ChevronRightIcon />
              <Typography>{t("about_page_title")}</Typography>
            </Box>
            <Typography className="text-banner">
              {t("about_page_title")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* <!-- Banner Area End -->
    <!-- Experience Area Start --> */}
      <Grid container>
        <Grid item md={7} xs={12}>
          <img src={womanTouch} />
        </Grid>
        <Grid
          item
          md={5}
          xs={12}
          sx={{
            "@media (max-width: 500px)": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            },
          }}
        >
          <Typography className="tag-title">{t("about_company")}</Typography>
          <Typography
            sx={{
              color: "#d61f23",
              fontSize: "30px",
              marginBottom: "50px",
              marginTop: "50px",
              "@media (max-width: 500px)": {
                marginBottom: "20px",
                marginTop: "20px",
              },
            }}
          >
            {t("about_aivision")}
          </Typography>
          <Typography
            sx={{
              lineHeight: "30px",
              textAlign: "justify",
              paddingRight: "30px",
              "@media (max-width: 500px)": {
                padding: "0px 10px",
              },
            }}
          >
            {t("about_page_description")}
          </Typography>
        </Grid>
      </Grid>

      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        mb={"15px"}
        sx={{
          background: "#f9f9f9",
          padding: "70px 0px 0px 0px",
          "@media (max-width: 500px)": {
            padding: "20px 0px 0px 0px",
          },
        }}
      >
        <ServiceOffter />
      </Box>
      {/**************** Team arear **************/}
      <Box
        pb={"100px"}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          "@media (max-width: 500px)": {
            overflow: "hidden",
          },
        }}
      >
        <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
          <Typography className="tag-title">{t("our_team")}</Typography>
          <Typography
            sx={{
              textTransform: "capitalize",
              fontSize: "40px",
              margin: "30px",
              "@media (max-width: 500px)": {
                fontSize: "24px",
                margin: "20px",
              },
            }}
          >
            {t("team_grid")}
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: "1000px",
          }}
        >
          <Slider {...setting}>
            {teamList.map((item, index) => {
              return (
                <div className="slide-item" key={index}>
                  <TeammateBox
                    avatar={item.image}
                    name={item.name}
                    role={item.role}
                  />
                </div>
              );
            })}
          </Slider>
        </Box>
      </Box>

      {/**************** Client arear **************/}
      <Box
        pb={"100px"}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          "@media (max-width: 500px)": {
            overflow: "hidden",
          },
        }}
      >
        <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
          <Typography className="tag-title">{t("our_client")}</Typography>
          <Typography
            sx={{
              textTransform: "capitalize",
              fontSize: "40px",
              margin: "30px",
              "@media (max-width: 500px)": {
                fontSize: "24px",
                margin: "20px",
              },
            }}
          >
            {t("client_grid")}
          </Typography>
        </Box>
        <Box sx={{ maxWidth: "1000px" }}>
          <Slider {...setting}>
            {clientList.map((item, index) => {
              return (
                <div className="slide-item" key={index}>
                  <div
                    style={{
                      margin: "5px 10px",
                      width: "150px",
                      height: "150px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item.img}
                      style={{
                        maxWidth: item.width ? item.width : "200px",
                        maxHeight: item.height ? item.height : "100px",
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
