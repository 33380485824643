import { useEffect, useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export function ImageHandle({ src, defaultSrc, ...rest}) {
    const [ imgSrc, setImgSrc ] = useState(null);

    const onImageError = (e) => {
        e.target.src = defaultSrc;
      };

    useEffect( () => {
        setImgSrc(src);
    }, [src]);

    return <LazyLoadImage src={imgSrc} onError={onImageError} {...rest}/>;
}