import { Box, Button, OutlinedInput, Typography } from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { isAuthCookie } from "utils/auth-cookie";
import { authAction } from "../saga/authSlice";
import { authApi } from "../authApi";

export const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggin = isAuthCookie();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const onSubmit = (data) => {
    if (!data) return;
    const { userName, password } = data;

    const toastId = toast.loading("Logging in...");
    authApi
      .getToken({ username: userName, password: password })
      .then((res) => {
        if (res.status === 200) {
          const { access_token, refresh_token, token_type } = res.data;
          dispatch(
            authAction.loginSuccess({
              access_token,
              refresh_token,
              token_type,
            })
          );
          toast.update(toastId, {
            render: "Login success",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
          navigate("/admin/blogs");
        } else {
          toast.update(toastId, {
            render: "Login failed",
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "Login failed",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      });
    // .finally(() => {
    //   toast.dismiss(toastId);
    // });
  };

  if (isLoggin) {
    return <Navigate to="/admin" />;
  }

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height={900}
    >
      <Typography variant="h3" my={5}>
        LOGIN PAGE
      </Typography>
      <Box
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
        width={450}
      >
        <Box mb={2}>
          <Box mb={1} ml={0.5}>
            <Typography component="label" variant="caption" fontWeight="bold">
              Email
            </Typography>
          </Box>
          <Controller
            name="userName"
            control={control}
            render={({ field }) => (
              <OutlinedInput
                {...field}
                size="small"
                fullWidth
                type="email"
                placeholder="Email"
                id="username"
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Box mb={1} ml={0.5}>
            <Typography component="label" variant="caption" fontWeight="bold">
              Password
            </Typography>
          </Box>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <OutlinedInput
                {...field}
                size="small"
                fullWidth
                type="password"
                placeholder="Password"
                id="password"
              />
            )}
          />
        </Box>
        <Box mt={4} mb={1}>
          <Button type="submit" variant="contained" color="info" fullWidth>
            sign in
          </Button>
        </Box>
        <Box mt={3} textAlign="center">
          <Typography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <Typography
              component={Link}
              //   to="/authentication/sign-up"
              variant="button"
              color="primary"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
