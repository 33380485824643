import { Box, Grid, Typography } from "@mui/material";
import serviceTagIcon from "../../assets/img/service-tag-icon.svg";
import serviceTagBgRed from "../../assets/img/service-tag-active.svg";
import serviceTagBgGray from "../../assets/img/service-tag.svg";
import serviceTagIconWhite from "../../assets/img/service-tag-icon-white.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ServiceBox({ title, description, icon, link }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "300px",
        height: "300px",
        borderRadius: "10px",
        boxShadow: "5px 5px 3px #c3c0c0",
        padding: "15px 15px",
        background: "white",
        margin: "10px",
      }}
    >
      <img src={icon} style={{ width: "60px" }} />
      <Typography
        sx={{ fontSize: "20px", marginTop: "20px", marginBottom: "10px" }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          marginBottom: "10px",
          height: "100px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "justify",
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: description }} />
        {/* <div>{description}</div> */}
      </Typography>
      <Typography
        onClick={() => navigate(link)}
        sx={{ cursor: "pointer", fontWeight: "600" }}
      >
        {t("read_more")}
      </Typography>
    </Box>
  );
}
