// react
import { CloudUploadOutlined, HighlightOffOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import ImageUploading from "react-images-uploading";
import ImageListItem from "./ImageListItem";

// add the check duplicate image later

export function ImageUpload({
  onChange,
  noEdit,
  isMultiple,
  imageList,
  maxImage,
  dataURLKey,
}) {
  const defaulMaxImage = 69;
  const defaultDataURLKey = "dataURL";

  let dataURLKey_ =
    typeof dataURLKey !== "undefined" ? dataURLKey : defaultDataURLKey;
  let maxImage_ = typeof maxImage !== "undefined" ? maxImage : defaulMaxImage;

  return (
    <div className="App h-full">
      <ImageUploading
        multiple={false}
        value={imageList}
        onChange={onChange}
        maxNumber={maxImage_}
        dataURLKey={dataURLKey_}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper h-full flex flex-col">
            <Box display="flex" p={1}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUploadOutlined />}
                disabled={noEdit}
                sx={{
                  textTransform: "none",
                  mx: 2,
                }}
                onClick={onImageUpload}
              >
                <Typography className="font-bold">Upload file</Typography>
              </Button>

              <Button
                variant="contained"
                color="error"
                startIcon={<HighlightOffOutlined />}
                disabled={noEdit}
                sx={{
                  textTransform: "none",
                }}
                onClick={onImageRemoveAll}
              >
                <Typography className="font-bold">Clear image</Typography>
              </Button>
            </Box>
            <Box
              display="flex"
              flex={1}
              px={3}
              height="100%"
              gap="10px"
              overflow="auto"
              justifyContent="center"
              sx={{
                scrollbarWidth: "thin",
              }}
            >
              {imageList.map((image, index) => (
                <ImageListItem
                  index={index + image[dataURLKey_]}
                  imagePath={image[dataURLKey_]}
                  onDelete={onImageRemove}
                  onUpdate={onImageUpdate}
                  disabled={noEdit}
                />
              ))}
            </Box>
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
