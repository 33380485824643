import { axiosClient } from "utils/axiosClient";

export const authApi = {
  getToken(data) {
    const url = "/auth/login";
    return axiosClient.post(url, data, {headers: {
      'Content-Type': 'multipart/form-data'
    }});
  },
  autoLogin() {
    const url = "/auth/login";
    const data = {
      username: "a@a.a",
      password: "1",
    }
    return axiosClient.post(url, data);
  }
};
