import { Box, Button, Divider, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { adminApi } from "../adminsApi";
import { BlogForm } from "../components/BlogForm";
import { resolver } from "../resolver";
import { useEffect } from "react";
import { useState } from "react";

export const BlogEditPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const initialValues = {
    title: "",
    content: "",
    image: [],
  };

  const {
    control,
    getValues,
    setValue,
    setFocus,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    resolver,
    defaultValues: initialValues,
  });

  const createBlog = async () => {
    await trigger(undefined, { shouldFocus: false });
    if (isValid) {
      const data = getValues();
      const toastId = toast.loading("Đang đăng bài...", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      adminApi
        .createBlog(data)
        .then((res) => {
          if (res.status === 200 || res.status_code === 200) {
            navigate("/admin/blogs");
            toast.update(toastId, {
              render: "Đăng bài thành công",
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });
          } else {
            toast.update(toastId, {
              render: "Đã xảy ra lỗi trong lúc đăng bài, vui lòng thử lại sau",
              type: "error",
              isLoading: false,
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          toast.update(toastId, {
            // render: "Đã xảy ra lỗi trong lúc đăng bài, vui lòng thử lại sau",
            render: err,
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
        });
    }
  };

  console.log(id)

  useEffect(() => {
    
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      alignItems="center"
    >
      <div className=" p-10 w-full max-w-[1200px]">
        <Box display="flex" flexDirection="column" width="100%">
          <div className="font-bold text-3xl text-primary">Viết Blog</div>
          <Divider sx={{ my: 1 }} />

          <>
            <Box
              className="bg-blue-200 bg-opacity-30 my-5"
              display="flex"
              flexDirection="column"
              width="100%"
              p={3}
              borderRadius="10px"
            >
              <BlogForm
                initialValues={initialValues}
                noEdit={false}
                control={control}
                errors={errors}
                setValue={setValue}
                uploadImageUrl="/posts/upload-content-images"
                setFocus={setFocus}
              />
            </Box>
          </>

          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            gap={2}
            p={1}
          >
            <Button
              variant="outlined"
              className="bg-primary"
              onClick={() => {
                navigate(`/admin/blogs`);
              }}
              sx={{
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              <Typography className="font-bold">Huỷ bỏ</Typography>
            </Button>

            <Button
              variant="contained"
              className="bg-primary"
              onClick={() => createBlog()}
              sx={{
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              <Typography className="font-bold">Đăng bài</Typography>
            </Button>
          </Box>
        </Box>
      </div>
    </Box>
  );
};
