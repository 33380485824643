import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blogList: [],
  blogContent: {
    created_at: "",
    author_id: null,
    title: "",
    image: "",
    is_ticked: false,
    updated_at: "",
    id: null,
    author_name: "",
    content: "",
    category_id: null,
  },
  categoryList: [],
  tags: [],
  isLoading: false,
  errormsg: "",
  total: 0,
  page: 1,
  totalPage: 0,
};

export const blogsSlice = createSlice({
  name: "blogs",
  initialState: initialState,
  reducers: {
    // Blog list
    getBlogs: (state) => {
      state.isLoading = true;
    },
    getBlogsSuccess: (state, action) => {
      console.log(action.payload);
      state.blogList = action.payload.data;
      state.total = action.payload.metadata.total;
      state.page = action.payload.metadata.page;
      state.totalPage = action.payload.metadata.total_page
      state.isLoading = false;
    },
    getBlogsFailed: (state, action) => {
      state.errormsg = action.payload;
      state.isLoading = false;
    },
    // Blog detail
    getBlogDetail: (state, action) => {
      state.isLoading = true;
    },
    getBlogDetailSuccess: (state, action) => {
      const data = action.payload.data;
      state.blogContent = data;
      // {
      //   createdAt: data.created_at,
      //   authorId: data.author_id,
      //   title: data.title,
      //   bannerImage: data.image,
      //   isTicked: data.is_ticked,
      //   updatedAt: data.updated_at,
      //   id: data.id,
      //   authorName: data.author_name,
      //   content: data.content,
      //   categoryId: data.category_id,
      // };
      state.isLoading = false;
    },
    getBlogDetailFailed: (state, action) => {
      state.errormsg = action.payload;
      state.isLoading = false;
    },
    // Category
    getCategories: (state, action) => {
      state.isLoading = true;
    },
    getCategoriesSuccess: (state, action) => {
      state.categoryList = action.payload.data;
      state.isLoading = false;
    },
    getCategoriesFailed: (state, action) => {
      state.errormsg = action.payload;
      state.isLoading = false;
    },
    // Tags
  },
});

export const blogsAction = blogsSlice.actions;

export const selectBlogList = (state) => state.blogs.blogList;
export const selectBlogContent = (state) => state.blogs.blogContent;

const blogsReducer = blogsSlice.reducer;
export default blogsReducer;
