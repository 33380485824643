import { Box, Button, Divider, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { adminApi } from "../adminsApi";
import { BlogForm } from "../components/BlogForm";
import { resolver } from "../resolver";

export const BlogDetailPage = () => {
  const navigate = useNavigate();

  const initialValues = {
    title: "",
    author_name: "",
    content: "",
    event_id: 0,
    categories: [],
    tags: [],
    post_image: [],
  };

  const {
    control,
    getValues,
    setValue,
    setFocus,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    resolver,
    defaultValues: initialValues,
  });

  const createBlog = async () => {
    await trigger(undefined, { shouldFocus: false });
    if (isValid) {
      const data = getValues();
      const toastId = toast.loading("Đang đăng bài...", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      adminApi
        .createBlog(data)
        .then((res) => {
          if (res.status === 201 || res.status_code === 201) {
            navigate("/admin/blogs");
            toast.update(toastId, {
              render: res.detail,
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });
          } else {
            toast.update(toastId, {
              render: res.detail,
              type: "error",
              isLoading: false,
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          toast.update(toastId, {
            // render: "Đã xảy ra lỗi trong lúc đăng bài, vui lòng thử lại sau",
            render: err,
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
        });
    }
  };

  return (
    <Box display="flex" flexDirection="column" width="100%" alignItems="center">
      <div className=" p-10 w-full max-w-[1200px]">
        <Box display="flex" flexDirection="column" width="100%">
          <Typography className="font-bold text-xl">
            <span>Viết Blog</span>
          </Typography>
          <Divider sx={{ my: 1 }} />

          <>
            <Box
              className="bg-blue-200 bg-opacity-30 my-5"
              display="flex"
              flexDirection="column"
              width="100%"
              p={3}
              borderRadius="10px"
            >
              <BlogForm
                initialValues={initialValues}
                noEdit={false}
                control={control}
                errors={errors}
                setValue={setValue}
                uploadImageUrl="/images/processed_post"
                setFocus={setFocus}
              />
            </Box>
          </>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="bg-blue-200 bg-opacity-30"
            p={5}
            borderRadius="10px"
          >
            <Box className="grid grid-cols-4 gap-9">
              <Button
                className="bg-primary"
                onClick={() => createBlog()}
                size="large"
                style={{ borderRadius: "12px" }}
              >
                <Typography className="font-bold  p-3">Đăng bài</Typography>
              </Button>

              <Button
                className="bg-primary"
                onClick={() => {
                  navigate(`/admin/blogs`);
                }}
                size="large"
                style={{ borderRadius: "12px" }}
              >
                <Typography className="font-bold  p-3">Huỷ bỏ</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    </Box>
  );
};
