import Footer from "../../components/Footer";
import Menu from "../../components/Menu/index";
import TopBar from "../../components/TopBar";
import service_1 from "../../assets/img/icon/services-1.png";
import service_11 from "../../assets/img/icon/services-11.png";
import service_2 from "../../assets/img/icon/services-2.png";
import service_22 from "../../assets/img/icon/services-22.png";
import service_3 from "../../assets/img/icon/services-3.png";
import service_33 from "../../assets/img/icon/services-33.png";
import assistant_ai from "../../assets/img/EC_Virtual_Assistant_AI_750.jpg";
import footerBg from "../../assets/img/shape/footer.png";
import banner from "../../assets/img/contact-banner.jpg";
import bannerLogo from "../../assets/img/home-banner-logo.svg";
import map from "../../assets/img/map.png";
import { Box, Button, Grid, Typography } from "@mui/material";
import ContactBox from "../../components/Contact";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ContactPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {/* <Menu currentTab={4} /> */}
      {/* <!-- Header Area End -->
    <!-- Banner Area Start --> */}
      <Box
        sx={{
          background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${banner})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: 600,
          marginBottom: "200px",
          "@media (max-width: 500px)": {
            height: 300,
            marginBottom: "20px",
          },
        }}
      >
        <Grid container height="inherit">
          <Grid
            item
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"end"}
            alignItems={"center"}
            mb="60px"
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              padding={"10px 20px"}
              sx={{
                background: "#d61f23",
                color: "white",
                fontSize: "30px",
                textTransform: "uppercase",
                marginBottom: "15px",
              }}
            >
              <Typography
                onClick={() => navigate("/")}
                sx={{ cursor: "pointer" }}
              >
                {t("home_page_title")}
              </Typography>
              <ChevronRightIcon />
              <Typography>{t("contact_page_title")}</Typography>
            </Box>
            <Typography className="text-banner">
              {t("contact_page_title")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* <!-- Banner Area End -->
    <!-- Experience Area Start --> */}
      <Grid>
        <ContactBox />
      </Grid>
      <Grid
        sx={{
          padding: "50px 50px",
          cursor: "pointer",
          background: "#f7f7f7",
          textAlign: "center",
          "@media (max-width: 500px)": {
            padding: "10px",
            marginBottom: "15px",
          },
        }}
        onClick={() =>
          window.open(
            "https://www.google.com/maps/place/202+%C4%90.+L%C3%AA+Lai,+Ph%C6%B0%E1%BB%9Dng+Ph%E1%BA%A1m+Ng%C5%A9+L%C3%A3o,+Qu%E1%BA%ADn+1,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam/@10.7688591,106.6878815,17z/data=!3m1!4b1!4m6!3m5!1s0x31752f3d71910547:0x2db232243b8db83!8m2!3d10.7688591!4d106.6904564!16s%2Fg%2F11hxmwjw25?hl=vi-VN&entry=ttu",
            "_blank"
          )
        }
      >
        <img
          // src="https://www.google.com/maps/vt/data=Elr9iHwfTf_ReeiRr5x1anf9wdYusU0sACL0Fxd-0CLVYI-5n0kHISiD24KjLrQGThfiO16sdQltDraU68QDPXXg7io47itKq2xXzuXzbc6COGMab2eEKpxH-t0nuADByQ5826yaeT-C-LLWkBRzP6PBqixdOqV2AyyMJp57JvecTuICfrDnt3WYjbgp9k-h2ryCtAg5bxTMN7R_lsIUZHiBQY8ZtecEw_i6pndITrrwlkwap5sxuMqsU5E77a4gAd4zN687D3QA6B9eA7MeZ9pJEst6ZBxRxzZ1"
          src={map}
          alt="map"
          style={{
            width: "80%",
          }}
          className="contact-map"
        />
      </Grid>
      <Footer />
    </>
  );
}
