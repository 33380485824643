import { Box, Grid, Typography } from "@mui/material";
import serviceTagIcon from "../../assets/img/service-tag-icon.svg";
import serviceTagBgRed from "../../assets/img/service-tag-active.svg";
import serviceTagBgGray from "../../assets/img/service-tag.svg";
import serviceTagIconWhite from "../../assets/img/service-tag-icon-white.svg";
import { useNavigate } from "react-router-dom";

export default function ServiceTag({ title, description, link }) {
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => navigate(link)}
      sx={{
        display: "flex",
        width: "300px",
        backgroundColor: "#cdcaca",
        backgroundImage: `url(${serviceTagBgGray})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: "15px 15px",
        cursor: "pointer",
        "&:hover": {
          color: "white",
          backgroundColor: "#d61f23",
          backgroundImage: `url(${serviceTagBgRed})`,
        },

        "&:hover > div > div:first-child": {
          backgroundImage: `url(${serviceTagIconWhite})`,
          width: "50px",
          height: "50px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        },
      }}
    >
      <Grid container display={"flex"} alignItems={"center"}>
        <Grid
          item
          xs={3}
          sx={{
            width: "50px",
            height: "50px",
            backgroundImage: `url(${serviceTagIcon})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        ></Grid>
        <Grid item container xs={8} display={"flex"} flexDirection={"column"}>
          <Typography>{title}</Typography>
          <Typography
            sx={{
              textOverflow: "ellipsis",
              width: "100%",
              height: "25px",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
