import ChevronRight from "@mui/icons-material/ChevronRight";
import { Box, Divider, Grid, Typography } from "@mui/material";
import banner from "assets/img/blog-banner.jpg";
import blogBanner from "assets/img/blog_1.jpg";
import Footer from "components/Footer";
import { ImageHandle } from "components/ImageHandler";
import { TextEditor } from "components/TextEditor";
import format from "date-fns/format";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { blogsAction, selectBlogContent } from "../saga/blogSlice";
import "./blogStyles.css";

export default function DetailPage() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [formatedDate, setFormatedDate] = useState();
  const [contentTableHeads, setContentTableHeads] = useState();

  const blogContent = useSelector(selectBlogContent);

  const navigate = useNavigate();

  const getHeading = (content) => {
    const parser = new DOMParser();
    const htmlDocument = parser.parseFromString(content, "text/html");

    const headings = Array.from(htmlDocument.querySelectorAll("h1, h2"));

    return headings.filter((e) => e.id);
  };

  useEffect(() => {
    dispatch(blogsAction.getBlogDetail(id));
  }, [id, dispatch]);

  useEffect(() => {
    const dataVal = blogContent && Date.parse(blogContent.created_at);
    dataVal && setFormatedDate(format(dataVal, "MMMM dd, yyyy"));

    blogContent && setContentTableHeads(getHeading(blogContent.content));
  }, [blogContent]);

  return (
    <>
      <Helmet>
        <meta property="og:title" content={blogContent.title} />
        <meta property="og:image" content={blogContent.image} />
      </Helmet>
      <Box
        sx={{
          background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${banner})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: 600,
          marginBottom: "50px",
          "@media (max-width: 500px)": {
            height: 300,
            marginBottom: "15px",
          },
        }}
      >
        <Grid container height="inherit">
          <Grid
            item
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"end"}
            alignItems={"center"}
            mb="60px"
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              padding={"10px 20px"}
              sx={{
                background: "#d61f23",
                color: "white",
                fontSize: "30px",
                textTransform: "uppercase",
                marginBottom: "15px",
              }}
            >
              <Typography
                onClick={() => navigate("/")}
                sx={{ cursor: "pointer" }}
              >
                Home
              </Typography>
              <ChevronRight />
              <Typography>Blog</Typography>
            </Box>
            <Typography className="text-banner">Blog</Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Content */}
      <Box
        padding={"50px 80px"}
        display={"flex"}
        justifyContent="center"
        sx={{
          "@media (max-width: 500px)": {
            padding: "10px",
          },
        }}
      >
        <Grid
          container
          spacing={10}
          sx={{
            display: "flex",
            maxWidth: "1200px",
            justifyContent: "center",
          }}
        >
          {/* Main content */}
          <Grid item xs={12}>
            <ImageHandle src={blogContent.image} defaultSrc={blogBanner} />
            <Typography sx={{ fontSize: "30px", margin: "20px 0" }}>
              {blogContent.title}
            </Typography>
            <Box
              width="100%"
              borderBottom={1}
              borderColor="divider"
              sx={{
                ".ck.ck-editor__main > .ck-editor__editable": {
                  bgcolor: "transparent",
                  border: "none",
                },
                "@media (max-width: 768px)": {
                  px: 1,
                },
              }}
            >
              <TextEditor
                initialData={blogContent.content}
                readonly={true}
                noEdit={true}
              />
            </Box>
          </Grid>

          {/* <Grid
            item
            md={4}
            sx={{
              "@media (max-width: 600px": {
                display: "none",
              },
            }}
          >
            <TableOfContent headingList={contentTableHeads} />
          </Grid> */}
        </Grid>
      </Box>

      <Footer />
    </>
  );
}
