export default function TopBar() {
  return (
    <div className="top__bar">
      <div className="container custom__container">
        <div className="row">
          <div className="col-xl-5 col-md-6">
            <div className="top__bar-left">
              <a href="#">
                <i className="far fa-map-marker-alt"></i>F.14, HM Town, 412
                Nguyen Thi Minh Khai, W. 05, D. 3, HCMC, Vietnam
              </a>
            </div>
          </div>
          <div className="col-xl-7 col-md-6">
            <div className="top__bar-right">
              <a href="tel:+84981419967" className="mr-10">
                <i className="fal fa-phone-alt"></i>+(84) 98 141 9967
              </a>
              <a href="mailto:contact@aivision.vn">
                <i className="fas fa-envelope"></i>contact@aivision.vn
              </a>
              <div className="top__bar-right-social">
                <ul>
                  <li>
                    <a href="https://www.linkedin.com/company/ai-vision">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
