// import logo from "../../assets/img/logo-1.png";
import logo from "../../assets/img/logo_aivision.png";
import logoMenu from "../../assets/img/icon/menu.png";
import {
  Grid,
  Box,
  Typography,
  Drawer,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
import { language } from "../../constants/common";
import { getLocalLanguage, setLocalLanguage } from "../../utils/storage";

export default function Menu(props) {
  const { t, i18n } = useTranslation();
  const { currentTab } = props;
  const navigate = useNavigate();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const menuList = [
    {
      name: t("home_page_title"),
      href: "/",
    },
    {
      name: t("about_page_title"),
      href: "/about-us",
    },
    {
      name: t("services_page_title"),
      href: "/services",
    },
    {
      name: t("blog_page_title"),
      href: "/blogs",
    },

    {
      name: t("contact_page_title"),
      href: "/contact",
    },
  ];
  const [lang, setLang] = useState(language[0].value);

  useEffect(() => {
    const langLocal = getLocalLanguage();
    if (langLocal) {
      // const langObj = language.filter((item) => item.value === lang);
      setLang(langLocal);
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  function handleChangeLanguage(value) {
    setLocalLanguage(value);
    setLang(value);
  }

  return (
    <Box sx={{
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      boxShadow: 1,
      zIndex: 999
    }}>
      <Grid container sx={{ justifyContent: "center", position: "relative" }}>
        <Grid
          item
          xs={2}
          md={4}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            position: "relative",
            height: "100px",
            "@media (max-width: 500px)": {
              height: "80px",
            },
          }}
        >
          <img
            src={logo}
            alt="logo"
            width={"150px"}
            className="logo-img"
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              margin: "auto",
            }}
          />
        </Grid>
        <Grid
          className="menu-desktop"
          item
          container
          xs={10}
          md={8}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {menuList.map((item, index) => {
            return (
              <Box
                className={`menu-link ${
                  currentTab === index ? "active-link" : ""
                }`}
                key={index}
                onClick={() => navigate(`${item.href}`)}
              >
                <Typography>{item.name}</Typography>
              </Box>
            );
          })}

          <Box className="menu-link nohover">
            <TextField
              select
              defaultValue={lang}
              size="small"
              onChange={(e) => handleChangeLanguage(e.target.value)}
            >
              {language.map((item, index) => {
                return <MenuItem value={item.value}>{item.label}</MenuItem>;
              })}
            </TextField>
          </Box>
        </Grid>
        <Grid className="menu-mobile">
          <MenuIcon onClick={() => setIsOpenMenu(true)} />
          <Box>
            <Drawer
              anchor={"right"}
              open={isOpenMenu}
              onClose={() => setIsOpenMenu(false)}
            >
              {menuList.map((item, index) => {
                return (
                  <Box
                    className={`menu-link ${
                      currentTab === index ? "active-link" : ""
                    }`}
                    key={index}
                    onClick={() => navigate(`${item.href}`)}
                    sx={{
                      width: "200px",
                      textAlign: "center",
                      margin: "20px 0",
                      "&:before": {
                        right: 0,
                        margin: "auto",
                      },
                    }}
                  >
                    <Typography>{item.name}</Typography>
                  </Box>
                );
              })}
              <Grid
                sx={{
                  width: "90%",
                  position: "absolute",
                  bottom: "50px",
                  right: 0,
                  left: 0,
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <Select
                  value={lang}
                  onChange={(e) => handleChangeLanguage(e.target.value)}
                >
                  {language.map((item, index) => {
                    return <MenuItem value={item.value}>{item.label}</MenuItem>;
                  })}
                </Select>
              </Grid>
            </Drawer>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
